import { Environment } from './environment.interface';

export const environment: Environment = {
  API_VERSION: 2,
  FRR_2023_ID: 29,
  FRR_ID: 41,
  SHOP_ID: 3,
  TOKEN_STORAGE_KEY: 'VISITOR_TOKEN',
  partners: {
    bottom: 1,
    destination: 8,
    industry: 11,
    info: 2,
    infoSupport: 14,
    subtitle: 4,
    support: 10,
    title: 9,
  },
  production: false,
};
